import React, { useState, useEffect } from "react";
import TimeRangeSlider from "./TimeRangeSlider";
import { ConfBookingUpdateView, ConfBookingListView } from "../../Api/Booking";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CountryCodeOptions from "../../../../utils/CountryCodeOptions";

const BookingConfigSelect = (props) => {
  const initialToken = useSelector((state) => state.login.token);
  const [token, setToken] = useState(initialToken);
  const { selectedBooking, id, setBookingData } = props;
  const [formChanged, setFormChanged] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("591");
  const [countryCodeChanged, setCountryCodeChanged] = useState(false);
  const [phoneChanged, setPhoneChanged] = useState(false);

  const handleCountryCodeChange = (value) => {
    setSelectedCountryCode(value); // Actualiza el código de país
    setFormState((prevState) => ({
      ...prevState,
      phone: "", // Borra el contenido del campo de teléfono
    }));
    setCountryCodeChanged(true); // Marca que el código de país ha cambiado
    setFormChanged(true); // Marca el formulario como cambiado
  };

  console.log("selectedBooking", selectedBooking);

  const [formState, setFormState] = useState({
    btn: "",
    status_conf: "",
    phone: "",
    email: "",
    max_personas: "",
    time_bet_booking: "",
    max_reservas: "",
    holiday: ["", "", "", "", "", "", ""],
    hora_inicio: "",
    hora_fin: "",
    hora_inicio_tarde: "",
    hora_fin_tarde: "",
    hora_inicio_noche: "",
    hora_fin_noche: "",
    kids: false,
    teen: false,
    description: "",
    title: "",
  });
  const [preview, setPreview] = useState(formState.title);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Llama a la función para obtener los datos
        const result = await ConfBookingListView(id, token);
        console.log("result de api confbookinglistview", result);

        setBookingData(result);
      } catch (error) {
        console.error(
          "Error al obtener datos de ConfBooking en bookingselect:",
          error
        );
      }
    };

    fetchData();
  }, [selectedBooking, id]);

  useEffect(
    (prevFormState) => {
      if (selectedBooking) {
        setFormState({
          ...prevFormState,
          btn: selectedBooking.btn || "",
          status_conf: selectedBooking.status_conf || 0,
          phone: selectedBooking.phone || "",
          email: selectedBooking.email || "",
          max_personas: selectedBooking.max_personas || "",
          time_bet_booking: selectedBooking.time_bet_booking || "",
          max_reservas: selectedBooking.max_reservas || "",
          holiday: selectedBooking.holiday
            ? JSON.parse(selectedBooking.holiday)
            : ["true", "false", "false", "false", "false", "false", "true"],
          hora_inicio: formatHour(selectedBooking.hora_inicio),
          hora_fin: formatHour(selectedBooking.hora_fin),
          hora_inicio_tarde: formatHour(selectedBooking.hora_inicio_tarde),
          hora_fin_tarde: formatHour(selectedBooking.hora_fin_tarde),
          hora_inicio_noche: formatHour(selectedBooking.hora_inicio_noche),
          hora_fin_noche: formatHour(selectedBooking.hora_fin_noche),
          kids: selectedBooking.kids || false,
          teen: selectedBooking.teen || false,
          description: selectedBooking.description || "",
          title: selectedBooking.title || "",
        });
      }
    },
    [selectedBooking]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    let finalPhone;
    if (countryCodeChanged || phoneChanged) {
      finalPhone = `${selectedCountryCode} ${formState.phone}`; // Incluye countryCode + phone
    } else {
      finalPhone = formState.phone; // Solo usa el valor de phone
    }

    try {
      const formattedFormState = {
        ...formState,
        customer_user: id,
        holiday: `[${formState.holiday.join(",")}]`,
      };
      const formattedDataForBackend = formatHoursForBackend(formattedFormState);
      const prevFormState = { ...formState }; // Initialize prevFormState here

      const response = await ConfBookingUpdateView(
        selectedBooking.id,
        formattedDataForBackend,
        token
      );

      // Verifica que la actualización fue exitosa
      if (response && response.id === selectedBooking.id) {
        // Actualiza el estado local con los nuevos datos, especialmente para la propiedad "btn"
        const updatedFormState = {
          ...prevFormState,
          btn: response.btn || "",
          status_conf: response.status_conf || 0,
          phone: finalPhone,
          email: response.email || "",
          max_personas: response.max_personas || "",
          time_bet_booking: response.time_bet_booking || "",
          max_reservas: response.max_reservas || "",
          holiday: JSON.parse(response.holiday) || ["", "", "", "", "", "", ""],
          hora_inicio: response.hora_inicio || "",
          hora_fin: response.hora_fin || "",
          hora_inicio_tarde: response.hora_inicio_tarde || "",
          hora_fin_tarde: response.hora_fin_tarde || "",
          hora_inicio_noche: response.hora_inicio_noche || "",
          hora_fin_noche: response.hora_fin_noche || "",
          kids: response.kids || false,
          teen: response.teen || false,
          description: response.description || "",
          title: response.title || "",
        };

        setFormState(updatedFormState);

        const updatedBookingData = await ConfBookingListView(id, token);
        props.setBookingData(updatedBookingData);

        // Transforma de nuevo las horas al formato requerido por TimeRangeSlider
        setFormState((prevFormState) => ({
          ...prevFormState,
          hora_inicio: formatHour(response.hora_inicio),
          hora_fin: formatHour(response.hora_fin),
          hora_inicio_tarde: formatHour(response.hora_inicio_tarde),
          hora_fin_tarde: formatHour(response.hora_fin_tarde),
          hora_inicio_noche: formatHour(response.hora_inicio_noche),
          hora_fin_noche: formatHour(response.hora_fin_noche),
        }));

        // Puedes agregar aquí lógica adicional, como mostrar un mensaje de éxito
        toast.success("Cambios guardados satisfactoriamente", {
          position: "top-center",
          bodyClassName: "text-sm",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
        });
      } else {
        // Puedes manejar un escenario donde la actualización no fue exitosa
        toast.error("Faltan configurar horarios del día", {
          position: "top-center",
          bodyClassName: "text-sm",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
        });
      }
    } catch (error) {
      // Maneja los errores, por ejemplo, muestra un mensaje de error
      console.error("Error al guardar los cambios:", error);
      toast.error("error al guardar cambios", {
        position: "top-center",
        bodyClassName: "text-sm",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
    } finally {
      setCountryCodeChanged(false);
      setPhoneChanged(false);
    }
  };

  const handleCheckboxChange = (value) => {
    // Cambia el estado basado en el valor actual de formState.status_conf
    setFormState((prevFormState) => ({
      ...prevFormState,
      status_conf: formState.status_conf === value ? "" : value,
    }));
    setFormChanged(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Verifica si el nombre del campo es 'phone'
    if (name === "phone") {
      setFormState((prevFormState) => ({
        ...prevFormState,
        [name]: value, // Actualiza el campo phone
      }));
      setPhoneChanged(true); // Marca que el campo phone ha cambiado
    } else if (name === "email") {
      // Actualiza solo el campo email
      setFormState((prevFormState) => ({
        ...prevFormState,
        [name]: value,
      }));
    } else {
      // Para otros campos, aplica la lógica existente
      setFormState((prevFormState) => ({
        ...prevFormState,
        [name]: name.includes("hora")
          ? formatHour(value) // Formatea horas
          : type === "checkbox"
          ? checked // Maneja checkboxes
          : value, // Para otros tipos de campo
      }));
    }
  };

  const handleDayClick = (index) => {
    setFormState((prevFormState) => {
      const updatedHoliday = [...prevFormState.holiday];
      updatedHoliday[index] = !updatedHoliday[index];
      setFormChanged(true);
      return { ...prevFormState, holiday: updatedHoliday };
    });
  };

  const formatHour = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  const formatTime = (minutes) => {
    try {
      if (typeof minutes === "number") {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${String(hours).padStart(2, "0")}:${String(
          remainingMinutes
        ).padStart(2, "0")}`;
      } else {
        console.error("Invalid minutes in formatTime:", minutes);
        return "00:00"; // Return a default value, adjust as needed.
      }
    } catch (error) {
      console.error("Error in formatTime:", error);
      throw error;
    }
  };

  const formatHoursForBackend = (formState) => {
    try {
      return {
        ...formState,
        hora_inicio: formatTime(formState.hora_inicio),
        hora_fin: formatTime(formState.hora_fin),
        hora_inicio_tarde: formatTime(formState.hora_inicio_tarde),
        hora_fin_tarde: formatTime(formState.hora_fin_tarde),
        hora_inicio_noche: formatTime(formState.hora_inicio_noche),
        hora_fin_noche: formatTime(formState.hora_fin_noche),
      };
    } catch (error) {
      console.error("Error in formatHoursForBackend:", error);
      throw error;
    }
  };

  if (selectedBooking === 0) {
    return (
      <div className="text-center mt-4 text-neutral-400">
        <p>Seleccione una configuración para cargar.</p>
      </div>
    );
  }

  const updatePreview = (value) => {
    setPreview(value);
  };

  return (
    <div className=" mt-2 flex flex-col items-center ">
      <form onSubmit={handleSubmit} className="mt-2  rounded text-sm w-full">
        <div className="flex flex-wrap mb-3">
          {/* sección de Botón */}

          <div className="w-full rounded-lg mb-3 bg-white p-4 shadow-lg border border-neutral-300">
            <label
              className="block font-semibold text-neutral-600 mb-3"
              htmlFor="title"
            >
              Texto de Botón:
              <input
                id="title"
                type="text"
                name="title" // Cambia el name a "title"
                value={formState.title}
                onChange={(e) => {
                  handleInputChange(e);
                  setFormChanged(true);
                  updatePreview(e.target.value); // Llama a la función para actualizar la vista previa
                }}
                maxLength={21} // Cambia la longitud máxima a 30
                className="w-full border border-gray-500 p-2 rounded-md focus:outline-none focus:border-blue-500 mb-3 mt-3 text-base font-medium"
                placeholder="Introduce el título"
              />
            </label>
            <label className="mb-1 font-semibold">
              Previsualizar:
              <div
                id="title-preview"
                className="ml-4 py-2 px-3 bg-orange-500 hover:bg-orange-400 cursor-pointer p-2 rounded-md mt-3 text-white text-center font-semibold inline-block"
              >
                {preview || formState.title}
              </div>
            </label>
          </div>

          {/* sección de TÍTULO */}
          <div className="w-full md:w-1/2 rounded-lg mb-3 md:mb-0 bg-white p-4 shadow-lg">
            <label
              className="block font-semibold text-neutral-600 mb-3"
              htmlFor="titulo"
            >
              Título :
              <input
                id="titulo"
                type="text"
                name="btn"
                value={formState.btn}
                onChange={(e) => {
                  handleInputChange(e);
                  setFormChanged(true);
                }}
                maxLength={20}
                className="w-full border border-gray-500 p-2 rounded-md focus:outline-none focus:border-blue-500 mb-3 mt-3 text-base font-medium"
                placeholder="Introduce el título"
              />
            </label>
            <label
              className="block mb-1 font-semibold text-neutral-600"
              htmlFor="description"
            >
              Descripción:
              <textarea
                id="description"
                name="description"
                value={formState.description}
                onChange={(e) => {
                  handleInputChange(e);
                  setFormChanged(true);
                }}
                className="w-full border border-gray-500 p-2 rounded-md focus:outline-none focus:border-blue-500 mt-3"
                placeholder="Introduce la descripción"
                rows="4"
              />
            </label>
          </div>

          {/* sección de STATUS CONF */}
          <div className="w-full md:w-1/2 rounded-lg bg-white p-8 shadow-lg md:ml-0">
            <p className="mb-3 font-semibold text-neutral-600">
              Tipo de Reservas (seleccionar)
            </p>
            <div className="flex flex-col ml-8 mt-8">
              <div className="mb-2 ">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={
                      formState.status_conf !== 1 && formState.status_conf !== 2
                    } // Desactivado
                    onChange={() => handleCheckboxChange(0)}
                    className="mr-2 appearance-none h-5 w-5 border border-gray-500 rounded-md checked:bg-red-500 "
                  />
                  <span className="text-base text-neutral-600">
                    Deshabilitar
                  </span>
                </label>
                <p className="text-xs font-medium text-neutral-500">
                  deshabilitar reservas
                </p>
              </div>

              <div className="mb-2 ">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formState.status_conf === 1} // Activado
                    onChange={() => handleCheckboxChange(1)}
                    className="mr-2 appearance-none h-5 w-5 border border-gray-500 rounded-md checked:bg-green-500 "
                  />
                  <span className="text-base text-neutral-600">Individual</span>
                </label>
                <p className="text-xs font-medium text-neutral-500">
                  una reserva por fecha y hora
                </p>
              </div>

              <div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formState.status_conf === 2} // Activado
                    onChange={() => handleCheckboxChange(2)}
                    className="mr-2 appearance-none h-5 w-5 border border-gray-500 rounded-md checked:bg-purple-500 "
                  />
                  <span className="text-base text-neutral-600">Grupal</span>
                </label>
                <p className="text-xs font-medium text-neutral-500">
                  varias reservas para una fecha y hora
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* sección de PHONE y EMAIL */}
        <div className=" mb-4 rounded-lg bg-white p-4 shadow-lg">
          {/* Campo de teléfono */}
          <label className=" mr-4 flex-1">
            <p className=" mb-3 font-semibold text-neutral-600">Teléfono:</p>
            <div className="items-center">
              {/* Campo de zona horaria */}
              <select
                className="border border-neutral-500 mr-1 rounded-lg text-xs py-3 p-1 bg-white"
                name="selectedCountryCode"
                value={selectedCountryCode} // Asegúrate de que esto esté conectado al estado
                onChange={(e) => handleCountryCodeChange(e.target.value)} // Llama a la función que actualiza el estado
              >
                <CountryCodeOptions />
              </select>
              {/* Campo de número de teléfono */}
              <input
                className="p-2 border border-neutral-500 rounded-lg w-2/4  text-base "
                type="tel"
                name="phone"
                value={formState.phone}
                onChange={(e) => {
                  handleInputChange(e);
                  setFormChanged(true);
                }}
              />
            </div>
          </label>

          {/* Campo de correo electrónico */}
          <label className="block ">
            <p className=" mb-3 font-semibold text-neutral-600">Email:</p>
            <input
              type="email"
              name="email"
              value={formState.email}
              onChange={(e) => {
                handleInputChange(e);
                setFormChanged(true);
              }}
              className="border border-neutral-500 p-2 rounded-lg  w-3/4 text-base "
            />
          </label>
        </div>

        {/* sección de MAX PERSONAS, TIME BET BOOKING Y MAX RESERVAS*/}
        <div className="flex mb-4  rounded-lg bg-white p-4 shadow-lg">
          <label className="block mr-3">
            <p className="mb-3 font-semibold text-neutral-600">
              Nº Personas por Reserva:
            </p>
            <input
              type="number"
              name="max_personas"
              value={formState.max_personas}
              onChange={handleInputChange}
              className="w-full p-2 border border-neutral-500 rounded text-xl   text-center"
            />
          </label>

          <label className="block ">
            <p className="mb-3 font-semibold text-neutral-600">
              Tiempo estimado por reservas:
            </p>
            <input
              type="number"
              name="time_bet_booking"
              value={formState.time_bet_booking}
              onChange={handleInputChange}
              className="w-full p-2 border border-neutral-500 rounded text-xl bg-neutral-100  text-center"
            />
            <p className="text-xs mb-3 font-semibold text-neutral-600">
              (minutos)
            </p>
          </label>

          <label
            className={`block ml-3 ${
              formState.status_conf !== 2 ? "hidden" : ""
            }`}
          >
            <p className="text-xs mb-3 font-semibold text-neutral-600">
              Nº de Reservas simultáneas:
            </p>
            <input
              type="number"
              name="max_reservas"
              value={formState.max_reservas}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-xl bg-neutral-200"
            />
          </label>
        </div>
        {/* sección de  DIAS HABILES*/}
        <div className="block mb-4 rounded-lg bg-white p-4 shadow-lg">
          <p className="mb-3 font-semibold text-neutral-600">
            Habilita tus Días Hábiles:
          </p>
          <div className="flex mt-2 overflow-x-auto text-center font-bold">
            {[
              "Domingo",
              "Lunes",
              "Martes",
              "Miércoles",
              "Jueves",
              "Viernes",
              "Sábado",
            ].map((day, index) => (
              <div key={index} className="flex-1 p-3 mr-2">
                <label
                  htmlFor={`checkbox-${index}`}
                  className={`relative block text-white rounded cursor-pointer flex-1 p-3 mr-2 ${
                    formState.holiday[index]
                      ? "bg-red-500 hover:bg-red-600"
                      : "bg-green-500 hover:bg-green-600"
                  }`}
                >
                  {day}
                </label>
                <input
                  type="checkbox"
                  checked={!formState.holiday[index]}
                  onChange={() => handleDayClick(index)}
                  id={`checkbox-${index}`}
                />
              </div>
            ))}
          </div>
        </div>
        {/* HORARIO */}
        <p className=" font-semibold text-neutral-600 mt-2 mb-3">
          Establecer Hora de Trabajo:
        </p>
        <div className="mb-4 flex flex-col items-left ">
          <label className="block mb-2">
            <div className="flex flex-col mt-2  mb-4 rounded-lg bg-white p-4 shadow-lg border border-neutral-300">
              <label className="text-sm text-neutral-700 font-semibold mt-3 mb-3">
                Horario de la Mañana
              </label>
              <div className="flex flex-col text-center rounded-lg bg-blue-400 p-4 shadow-lg mb-4">
                <div className="w-full">
                  <TimeRangeSlider
                    value={[formState.hora_inicio, formState.hora_fin]}
                    onChange={(value) => {
                      setFormState((prevFormState) => ({
                        ...prevFormState,
                        hora_inicio: value[0],
                        hora_fin: value[1],
                      }));
                      setFormChanged(true);
                    }}
                    min={6 * 60}
                    max={11 * 60 + 59}
                    initialValues={[formState.hora_inicio, formState.hora_fin]}
                    sliderColor="#fff"
                  />
                </div>
              </div>

              <label className="text-sm text-neutral-700 font-semibold mt-3 mb-3">
                Horario de la Tarde
              </label>
              <div className="flex flex-col text-center rounded-lg bg-orange-400 p-4 shadow-lg mb-4">
                <div className="w-full">
                  <TimeRangeSlider
                    value={[
                      formState.hora_inicio_tarde,
                      formState.hora_fin_tarde,
                    ]}
                    onChange={(value) => {
                      setFormState((prevFormState) => ({
                        ...prevFormState,
                        hora_inicio_tarde: value[0],
                        hora_fin_tarde: value[1],
                      }));
                      setFormChanged(true);
                    }}
                    min={12 * 60}
                    max={18 * 60 + 59}
                    sliderColor="#fff"
                  />
                </div>
              </div>

              <label className="text-sm text-neutral-700 font-semibold mt-3 mb-3">
                Horario de la Noche
              </label>
              <div className="flex flex-col text-center rounded-lg bg-purple-500 p-4 shadow-lg mb-4">
                <div className="w-full">
                  <TimeRangeSlider
                    value={[
                      formState.hora_inicio_noche,
                      formState.hora_fin_noche,
                    ]}
                    onChange={(value) => {
                      setFormState((prevFormState) => ({
                        ...prevFormState,
                        hora_inicio_noche: value[0],
                        hora_fin_noche: value[1],
                      }));
                      setFormChanged(true);
                    }}
                    min={19 * 60}
                    max={23 * 60 + 59}
                    sliderColor="#fff"
                  />
                </div>
              </div>
            </div>
          </label>
        </div>

        {/* sección de KIDS y Babies */}
        <p className="mb-3 font-semibold text-neutral-600">Otros Ajustes:</p>
        <div className="flex mb-4  rounded-lg bg-white p-4 shadow-lg">
          <div className="w-1/2 pr-2">
            <label className="block relative">
              <p className="font-semibold text-neutral-600 mb-2">Niños:</p>
              <div
                className={`ml-2 relative rounded-full w-10 h-6 transition-all duration-300 ${
                  formState.kids ? "bg-green-500" : "bg-red-500"
                }`}
                onClick={() => {
                  setFormState((prevFormState) => ({
                    ...prevFormState,
                    kids: !prevFormState.kids,
                  }));
                  setFormChanged(true);
                }}
              >
                <div
                  className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-all duration-300 ${
                    formState.kids
                      ? "bg-white transform translate-x-full"
                      : "bg-white"
                  }`}
                />
              </div>
            </label>
          </div>
          <div className="w-1/2 pl-2">
            <label className="block relative">
              <p className="font-semibold text-neutral-600 mb-2">Bebés:</p>
              <div
                className={`ml-2 relative rounded-full w-10 h-6 transition-all duration-300 ${
                  formState.teen ? "bg-green-500" : "bg-red-500"
                }`}
                onClick={() => {
                  setFormState((prevFormState) => ({
                    ...prevFormState,
                    teen: !prevFormState.teen,
                  }));
                  setFormChanged(true);
                }}
              >
                <div
                  className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-all duration-300 ${
                    formState.teen
                      ? "bg-white transform translate-x-full"
                      : "bg-white"
                  }`}
                />
              </div>
            </label>
          </div>
        </div>

        <button
          type="submit"
          disabled={!formChanged}
          className={`w-3/6 p-2 py-3  bg-blue-500 text-white rounded hover:bg-blue-600 ${
            formChanged ? "" : "cursor-not-allowed opacity-50"
          } mx-auto flex items-center justify-center mb-6`}
        >
          Guardar Cambios
        </button>
      </form>
    </div>
  );
};

export { BookingConfigSelect };
